export const MAX_PERCENT = 100;
export const MAX_AMOUNT = 999;

export const numberValidation = (value: number): number | undefined => {
  const number = Number(value);
  if (Number.isNaN(number) || number < 0) {
    return undefined;
  }
  return number;
};
